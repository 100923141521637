.conten_spiner{
    position: relative;
    display: inline-block;
}
.spiner{
    margin: 0;
    padding: 0;
    font-size: xx-large;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select:none;
    animation-name: spin;
    vertical-align: middle;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 

}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}