*{
    font-size: 16px;
}
.nav-lateral{
    top: 0;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    background:#212529!important;
    padding-top: 10px;
    height: 100vh;
    z-index: 100;
    width: 16rem;
    transition: width .4s ease-in-out 0s;
}
.nav-lateral ul{
    padding-inline-start: 0;
}
.nav-lateral ul li {
    display:block;
    float:none;
    border-bottom:1px solid rgba(255,255,255, .3);
}
.nav-lateral ul li button {
    user-select:none;
    outline: none;
    border: none;
}
.nav-lateral ul li button:focus {
    outline: none;
    border: none;
}
.nav-lateral ul li a{
    display: block;
    padding-left: 1rem;
    color: #e8e9ec;
    padding-top: 1.5rem;
}
.nav-lateral ul li a:hover{
    background-color: #428bca;
}
.nav-lateral ul li a i{
    font-size: larger;
}
.page-container{
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: flex-start;
    /* background-color: #585858; */
}

.bt-hamburger {
    --hamburger-color: #fff;
    user-select:none;
    background-color:transparent;
    display: inline-block;
    cursor: pointer;
    padding: 1px 5px 1px 5px;
    border-radius:.30rem;
    width: 40px;
    box-sizing: border-box;
    border-width: 0;
}
.bt-hamburger:hover{
    --hamburger-color: #ee7593;
}

.bt-hamburger .line-hamburger{
    background-color: var(--hamburger-color);
    width: 100%;
    height: 5px;
    margin: 5px 0;
    border-radius: 5px;
}

::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}
.cardE:focus, .cardE:hover {
    -webkit-box-shadow: 0.4px 0.4px 20px 0 rgb(199 199 199 / 80%);
    -moz-box-shadow: .4px .4px 20px 0 rgba(199,199,199,.8);
    box-shadow: 0.4px 0.4px 20px 0 rgb(199 199 199 / 80%);
}
.cardE{
    -webkit-box-shadow: 0.4px 0.4px 20px 0 rgb(199 199 199 / 50%);
    -moz-box-shadow: .4px .4px 20px 0 rgba(199,199,199,.5);
    box-shadow: 0.4px 0.4px 20px 0 rgb(199 199 199 / 50%);
    border: none;
    user-select:none;
}
.wh-spc-nowrap-chill *{
    white-space: nowrap;
}
.myTooltip{
    max-width: 300px;
}
.conten-header{
    background-color: #292929;
    /*background: linear-gradient(90deg, rgba(7,30,115,1) 0%, rgba(0,162,202,1) 100%);*/
    width: 100%;
}
.perfil {
    display:flex;
    position: relative;
    overflow: hidden;
    width: 50px;
    height: 50px;
    border-radius: 50% 50%;
    background-size: cover;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center center;
}
.perfil img{
    height: 100%;
    width: auto;
}
.zindex-dropdown{
    z-index: 1000;
}
.zindex-sticky{
    z-index: 1020;
}
.zindex-fixed{
    z-index: 1030;
}
.zindex-modal-backdrop{
    z-index: 1040;
}
.zindex-offcanvas{
    z-index: 1050;
}
.zindex-modal{
    z-index: 1060;
}
.zindex-popover{
    z-index: 1070;
}
.zindex-tooltip{
    z-index: 1080;
}