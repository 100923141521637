body {
  font-family: "Karla", sans-serif;
  background-color: #d0d0ce;
  
}
.login-container{
  width: 100%;
  height: 100vh;
  display: flex;
}

.brand-wrapper {
  margin-bottom: 19px;
}
.brand-wrapper .logo {
  height: 37px;
}

.login-card {
  margin: auto;
  border: 0;
  border-radius: 27.5px;
  box-shadow: 0 10px 30px 0 rgba(172, 168, 168, 0.43);
  overflow: hidden;
  max-width: 1000px;
}
.login-card-img {
  border-radius: 0;
  /* position: absolute; */
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
/* .login-card .card-body {
  padding: 85px 60px 60px;
} */
@media (max-width: 422px) {
  .login-card .card-body {
    padding: 35px 24px;
  }
}
.login-card-description {
  font-size: 25px;
  color: #000;
  font-weight: normal;
  margin-bottom: 23px;
}
.login-card form {
  max-width: 326px;
}
/* .login-card .form-control {
  border: 1px solid #d5dae2;
  padding: 15px 25px;
  margin-bottom: 20px;
  min-height: 45px;
  font-size: 13px;
  line-height: 15;
  font-weight: normal;
} */
.login-card .form-control::-webkit-input-placeholder {
  color: #919aa3;
}
.login-card .form-control::-moz-placeholder {
  color: #919aa3;
}
.login-card .form-control:-ms-input-placeholder {
  color: #919aa3;
}
.login-card .form-control::-ms-input-placeholder {
  color: #919aa3;
}
.login-card .form-control::placeholder {
  color: #919aa3;
}
.login-card .login-btn {
  padding: 13px 20px 12px;
  background-color: #00318d;
  border-radius: 4px;
  font-size: 17px;
  font-weight: bold;
  line-height: 20px;
  color: #fff;
  margin-bottom: 24px;
}
.login-card .login-btn:hover {
  border: 1px solid #00318d;
  background-color: transparent;
  color: #00318d;
}
.login-card .forgot-password-link {
  font-size: 14px;
  color: #919aa3;
  margin-bottom: 12px;
}
.login-card-footer-text {
  font-size: 16px;
  color: #0d2366;
  margin-bottom: 60px;
}
@media (max-width: 767px) {
  .login-card-footer-text {
    margin-bottom: 24px;
  }
}
.login-card-footer-nav a {
  font-size: 14px;
  color: #919aa3;
}


@media (max-width: 770px){
  .login-card-img{
    max-height: 45vh;
  }
}

