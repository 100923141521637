body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.custom-select {
  display: block;
  font-size: 1rem;
  font-weight: 400;
  color: #212529;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  appearance: none;
  line-height: 1.25;
}

.custom-select div {
  padding-top: 0.57rem;
  border: none;
  border-radius: .375rem;
}

.custon-select-label {
  opacity: .65;
  transform: scale(.85) translateY(-.5rem) translateX(.15rem);
}
.react-select-btsp__control {
  position: absolute !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.react-select-btsp__value-container {
  margin-top: 1.25rem;
  padding-bottom: .625rem;
}

span[class^="icon-"] {
  font-size: x-large;
}

.nav-accordion li{
  padding: 0;
}

.bg-uvilor-g {
  background-color: #4F4F4F !important;
}